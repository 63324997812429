import React from 'react'

const About = () => {
    return (
        <main className='About'>
            <h2>About</h2>
            <p style={{ marginTop: "1rem" }}>This blog app is a project in the Learn React tuturial series</p>
        </main>
    )
}

export default About